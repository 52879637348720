.clientInfo{
    text-align:left;

}
.clientInfo td{
    padding:0 0 0 5vw;
}

.clientInfo table{
    border:1px solid black;
    padding:1vw;
    margin:1vw;
    border-radius:15px;
    background-color:black;
}

tr.editClientInfo td{
    text-align:left;
    margin:0;
    padding:0;
}

tr.editClientInfo td:hover{
    color:greenyellow;
}
tr.editClientInfo td span{
    cursor:pointer;
}

.clientInfo input{
    width:30vw;
    height:3vh;
    border-radius:0.5vw;
    font-size:1em;

}
.clientInfo td select{
    height:4vh;
    font-size:1em;
    width:5vw;
}

.clientInfo input[type=number]{
    width:25vw;

}

.changeClientDataButton{
    position:absolute;
    bottom:10vh;
    width:20vw;
    left:40vw;
}

.changeClientDataButton button{
    margin:1vh 0 0 0;
    -moz-box-shadow: 0px 10px 14px -7px #276873;
    -webkit-box-shadow: 0px 10px 14px -7px #276873;
    box-shadow: 0px 10px 14px -7px #276873;
    background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #599bb3), color-stop(1, #408c99));
    background:-moz-linear-gradient(top, #599bb3 5%, #408c99 100%);
    background:-webkit-linear-gradient(top, #599bb3 5%, #408c99 100%);
    background:-o-linear-gradient(top, #599bb3 5%, #408c99 100%);
    background:-ms-linear-gradient(top, #599bb3 5%, #408c99 100%);
    background:linear-gradient(to bottom, #599bb3 5%, #408c99 100%);
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#599bb3', endColorstr='#408c99',GradientType=0);
    background-color:#599bb3;
    -moz-border-radius:8px;
    -webkit-border-radius:8px;
    border-radius:8px;
    display:inline-block;
    cursor:pointer;
    color:#ffffff;
    font-family:Arial;
    font-size:20px;
    font-weight:bold;
    padding:13px 32px;
    text-decoration:none;
    text-shadow:0px 1px 0px #3d768a;
}

.changingDataLoadingBox{
    position:relative;
    width:40vw;
    left:30vw;
}
.mainPageTopNavigationOutter{
    display: table-cell; vertical-align: middle;
    /*background-color: rgba(191, 191, 191, 0.98);*/
    /*color: #3c3c3c;*/
    background-color:black;
    color:white;
    height:10vh;
    width:100vw;
    font-size:1.5em;
    font-family: 'Trebuchet MS', serif

}

.mainPageTopNavigationMiddle{
    position: absolute; top: 50%;display: table-cell; vertical-align: middle;

}
.mainPageTopNavigationInner{
    position: relative; top:-50%;

}

.selected_true{
    margin:0 1vw;
    cursor:none;
    color: #41ff5e;
    border-bottom: 0.5vh solid white;
}

.selected_false{
    margin:0 1vw;
    cursor:pointer;
}

.selected_false:hover{
    color: #6f6bff;
}

.topNavigationLogo img{
    position:absolute;
    right:0;
    top:0;
    height:10vh;

}

.navigationOutter{
    position:relative;
    top:0;
    width:10vw;
    height:3vh;
    float:left;
}

.navigationDescription{
    position:relative;
    height:25%;
    font-size:0.5em;
    bottom:0.5vh;

}
.navigationLabel{
    position:relative;
}
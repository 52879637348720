.quotation{
    text-align:left;
    margin:0 0 0 1vw;
    overflow: auto;
    height:84vh;
    width:85vw;
}

.isEmailValid{
    color:green;
}

.searchBox, .registrationFields, .basicInfo{
    border:1px solid black;
    border-radius:5px;
    margin:1vh 0 2vh 0;
}

.basicInfo h4, .basicInfo p{
    margin:0 0 0 1vw;

}
.searchBox h5{
    margin:0 0 1vh 1vw;

}

.searchDropDownOutter{
    width:80vw;
    margin:0 0 2vh 1vw;
}

.searchIcon{
    position:relative;
    top:1vh;
    left:0;
    margin:0;
    padding:0;
    float:left;


}

.searchDropDownInner{
    position:relative;
    top:0;
    margin:0 0 0 2vw;
}
.myButton {
    margin:1vh 0 0 0;
    -moz-box-shadow: 0px 10px 14px -7px #276873;
    -webkit-box-shadow: 0px 10px 14px -7px #276873;
    box-shadow: 0px 10px 14px -7px #276873;
    background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #599bb3), color-stop(1, #408c99));
    background:-moz-linear-gradient(top, #599bb3 5%, #408c99 100%);
    background:-webkit-linear-gradient(top, #599bb3 5%, #408c99 100%);
    background:-o-linear-gradient(top, #599bb3 5%, #408c99 100%);
    background:-ms-linear-gradient(top, #599bb3 5%, #408c99 100%);
    background:linear-gradient(to bottom, #599bb3 5%, #408c99 100%);
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#599bb3', endColorstr='#408c99',GradientType=0);
    background-color:#599bb3;
    -moz-border-radius:8px;
    -webkit-border-radius:8px;
    border-radius:8px;
    display:inline-block;
    cursor:pointer;
    color:#ffffff;
    font-family:Arial;
    font-size:20px;
    font-weight:bold;
    padding:13px 32px;
    text-decoration:none;
    text-shadow:0px 1px 0px #3d768a;
}
.myButton:hover {
    background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #408c99), color-stop(1, #599bb3));
    background:-moz-linear-gradient(top, #408c99 5%, #599bb3 100%);
    background:-webkit-linear-gradient(top, #408c99 5%, #599bb3 100%);
    background:-o-linear-gradient(top, #408c99 5%, #599bb3 100%);
    background:-ms-linear-gradient(top, #408c99 5%, #599bb3 100%);
    background:linear-gradient(to bottom, #408c99 5%, #599bb3 100%);
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#408c99', endColorstr='#599bb3',GradientType=0);
    background-color:#408c99;
}
.myButton:active {
    position:relative;
    top:1px;
}


.quoteCart{
    border: 1px solid black;
    border-radius:5px;
    margin: 2vh 0 0 0;
}
.quoteCart div{

    margin: 1vh 0 1vh 1vw;
}

.quotationSentSuccessfully{
    border: 1px solid darkblue;
    border-radius:10px;
    height:20vh;
    width:30vw;
    left:35vw;
    position:absolute;
    top:10vh;
}

.sendingQuotation{
    border: 1px solid darkblue;
    border-radius:10px;
    height:20vh;
    width:30vw;
    left:35vw;
    position:absolute;
    top:10vh;
}

.emailInput{
    width:50vw;
}

.registrationFields table tr .emailInput {
    display:table-cell; width:30vw;
}

.registrationFields table tr .emailInput input {
    display:table-cell; width:90%;
    border-radius:5px;
    height:3vh;
    font-size:1.25vw;
}

.emailMatch{
    color:darkgreen;
    margin:0 0 0 1vw;
}

.emailDoesNotMatch{
    color:red;
    margin:0 0 0 1vw;
}

.registrationFields tbody table{
    margin:0 0 0 1vw;
}


/*React -select style*/

[class^=' css']+[class*='-control'], [class*=' css'] {
    color:white;
    background-color:black;
}



div [class*='placeholder']{
    color:white;
    size:1.5em;
    font-weight:bold;
}

[class*='-option']:hover{
    color:black;
    background-color: white;
}

[class*='-MenuList']{
    border:1px solid white;
}
[class*='-MenuList'] div{
    color:white;
    background-color: black;
    opacity:0.8;
}



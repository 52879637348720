.registrationInformation{
    border: 1px solid white;
    width:85vw;
    left:1vw;
    padding:1vh 1vw 1vh 0;
    border-radius:5px;
    margin:1vh 0 0 1vw;

}

.registrationInformation p{
    text-align:left;
    margin:0 0 0 0;
    padding:0 0 0 1vw;
    font-size:0.75em;

}

h6{
    padding:0;
    margin:1vh 0 1vh 0;

}

.registrationInputs{
    text-align:left;
    margin:1vh 0 0 1vw ;
    display:table-cell;
}

.registrationInputs input{
    width:30vw;
    height:5vh;
    border-radius:1vh;
    font-size:1em;
    background:black;
    color:white;

}

.registrationInputs div{
    width:40vw;
    margin:2vh 0 0 1vw ;
    padding:0 0 0 0 ;
    float:left;
    background:black;
    color:white;

}

.registrationInputs select{
    font-size:1em;
    background:black;
    color:white;

}
.registrationInputs select .landlineDDD{
    width:10vw;
    background:black;
    color:white;
}
.registrationInputs select .landlineNumber{
    width:20vw;
    background:black;
    color:white;
}
.registrationInputs tbody table tr td span.landlineNumber input{
    width:12vw;
    background:black;
    color:white;

}

.registrationItems{
    border: 1px solid white;
    width:85vw;
    left:1vw;
    padding:1vh 1vw 1vh 0;
    border-radius:5px;
    margin:1vh 0 0 1vw;

}
input.cep{
    width:19.5vw;
    margin:0 0 0 1vw;
}

span.label{
    margin:0 0 0 1vw;
}

.mandatory{
    color:red;
}

.registerButton button{
    border-radius:5px;
    width:10vw;
    height:5vh;
    margin:3vh 0 0 0 ;
    font-size:1em;
    background-color:forestgreen;
    box-shadow: 0.25vw 0.5vh #888888;
}

.registerButton button:hover{
    border-radius:5px;
    width:10vw;
    height:5vh;
    margin:3.25vh 0 0 0 ;
    font-size:1em;
    background-color:forestgreen;
    box-shadow: 0.25vw 0.5vh rgba(0, 0, 0, 0.99);
}


.animation {
    /*width: 100px;*/
    /*height: 100px;*/
    font-size:2em;
    left:0; top:70vh;
    text-align:left;
    margin: 1vh 0 0 1vw;
    position: relative;
    -webkit-animation-name: example; /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 5s; /* Safari 4.0 - 8.0 */
    animation-name: example;
    animation-duration: 5s;
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes example {
    0%   { left:0; top:0;}
    /*25%  {left:25vw; top:0;}*/
    /*50%  {left:50vw; top:0;}*/
    /*75%  {left:70vw; top:0;}*/
    100% {left:100vw; top:0;}
}

/* Standard syntax */
@keyframes example {
    0%   { left:0; top:70vh;}
    /*1%   { left:1vw; top:69vh;}*/
    /*2%   { left:1vw; top:68vh;}*/
    /*5%   { left:5vw; top:65vh;}*/
    /*10%   { left:10vw; top:60vh;}*/
    /*15%   { left:15vw; top:55vh;}*/
    /*20%   { left:20vw; top:50vh;}*/
    /*25%  {left:25vw; top:45vh;}*/
    /*30%  {left:30vw; top:40vh;}*/
    /*35%  {left:35vw; top:35vh;}*/
    /*40%  {left:40vw; top:30vh;}*/
    /*45%  {left:45vw; top:25vh;}*/
    /*50%  {left:50vw; top:20vh;}*/
    /*75%  { left:75vw; top:-5vh;}*/
    100% { left:100vw; top:-30vh;}
}


.sending{
    position:fixed;
    top:20%;
    left:40%;
}

.littlePlane{
    font-size:2em;
    color: #51b2ff;
    -ms-transform: rotate(-45deg); /* IE 9 */
    -webkit-transform: rotate(-45deg); /* Safari prior 9.0 */
    transform: rotate(-45deg); /* Standard syntax */
}

.clickHere{
    color:red;
    cursor:pointer;
}

.registrationModal{
    z-index:2005;
    background-image:url(../resources/white_opaque_pixel.png);
    background-repeat: repeat;
}


.forgotPasswordModal .animation {
    /*width: 100px;*/
    /*height: 100px;*/
    font-size:2em;
    text-align:left;
    -webkit-animation-name: example; /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 5s; /* Safari 4.0 - 8.0 */
    animation-name: example;
    animation-duration: 5s;
    z-index:1000;
}

.forgotPasswordModal .loginOutterBox{
    margin:0;
    padding:0;
    height:100%;
    width:100%;
}

.forgotPasswordModal .loginOutterBox .loginBox{
        top:10vh;
}
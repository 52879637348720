.loginBox{
    height:60vh;
    width:50vw;
    background-image:url(../resources/test.jpg);
    background-repeat: repeat;
    text-align:center;
    margin: 0 auto;
    top:10vh;
    position:relative;
    border-radius:10px;
    z-index:10;
    color:black;
}

.loginOutterBox{

}

.loginField{
    width:100%;
    top:10%;
    position:relative;
}
.loginField .loginLabel{
    width:50%;
    text-align:left;
    margin:0 0 0 1vw;
    left:25%;
    position:relative;
}

.loginField .loginInput{
    width:50%;
    text-align:left;
    margin:0 0 0 1vw;
    left:25%;
    position:relative;
}

.loginBox input{
    width:20vw;
    height:5vh;
    border-radius:5px;
    font-size:1em;
}

.loginField button{
    margin:1vh 0 0 0;
    -moz-box-shadow: 0px 10px 14px -7px #276873;
    -webkit-box-shadow: 0px 10px 14px -7px #276873;
    box-shadow: 0px 10px 14px -7px #276873;
    background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #599bb3), color-stop(1, #408c99));
    background:-moz-linear-gradient(top, #599bb3 5%, #408c99 100%);
    background:-webkit-linear-gradient(top, #599bb3 5%, #408c99 100%);
    background:-o-linear-gradient(top, #599bb3 5%, #408c99 100%);
    background:-ms-linear-gradient(top, #599bb3 5%, #408c99 100%);
    background:linear-gradient(to bottom, #599bb3 5%, #408c99 100%);
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#599bb3', endColorstr='#408c99',GradientType=0);
    background-color:#599bb3;
    -moz-border-radius:8px;
    -webkit-border-radius:8px;
    border-radius:8px;
    display:inline-block;
    cursor:pointer;
    color:#ffffff;
    font-family:Arial;
    font-size:20px;
    font-weight:bold;
    padding:13px 32px;
    text-decoration:none;
    text-shadow:0px 1px 0px #3d768a;
}

.loginField button:hover{
    background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #679962), color-stop(1, #598654));
    background:-moz-linear-gradient(top, #679962 5%, #598654 100%);
    background:-webkit-linear-gradient(top, #679962 5%, #598654 100%);
    background:-o-linear-gradient(top, #679962 5%, #598654 100%);
    background:-ms-linear-gradient(top, #679962 5%, #598654 100%);
    background:linear-gradient(to bottom, #679962 5%, #598654 100%);
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#408c99', endColorstr='#599bb3',GradientType=0);
    background-color: #679962;
}

.authenticationError{
    z-index:22;
    color:red;
    position:relative;
    top:10vh;

}

.forgotPassword:hover{
    cursor:pointer;
}

.forgotPasswordModal .loginOutterBox .loginBox{
    background:black;
    color:white;
}

.forgotPasswordModal .loginOutterBox .loginBox p{
    text-align:left;
    margin:0;
    padding:1vh;
}

.forgotPasswordModal .loginOutterBox .loginBox h4{
    margin:0;
    padding:0;
}
.forgotPasswordModal .loginOutterBox .loginBox .loginField{
    margin:0;
    padding:0;
}

.forgotPasswordModal{
    position:absolute;
    opacity:1;

    width:50vw;
    height:50vh;
    left:25vw;
    top:10vh;
}

.closeModal{
    height:4vh;
    width:4vh;
    background-color:red;
    z-index:1000;
    position:absolute;
    border-radius:5px;
    top:10vh;
    right:0;
}
.settings{
    width:15vw;
    height:84vh;
    opacity:0.8;

    border-radius:5vh 0 0 5vh;
    position:relative;
    float:left;
    text-align:left;
    margin:0 0 0 0;
    padding:0;
    background:grey;
}

.settings p{
    margin:2vh 0 0 2vw;
    padding:0;
    font-size:1.25em;
    cursor:pointer;
}
.settings p:hover{
    color:coral;
}

.settings p.selected{
    color:blue;
}
.settings-area{
    color:white;
    position:absolute;
    width:60vw;
    background-color:black;
    height:80vh;
    left: 20vw;
    top:3vh;
    margin:0;
    padding:0;
    border-radius:15px;

}
.settings-area h1,.settings-area h6{
    color:white;
    margin:2vh 0 0 0;
}
.settings-area p{
    color:white;
    margin:2vh 0 0 0;
}


.settings-area p.settingsTitle, .settings-area div.newPasswordOutter{
    color:white;
    margin:2vh 0 0 0;
}

.newPasswordLabel{
    position:relative;
    left:5vw;
    width:15vw;
    margin:0vw;
    float:left;
}
.newPasswordOutter input{
    position:relative;
    margin:0;
    height:3vh;
    left:-5vw;
    border-radius: 15px;
}

.settings-area button{
    margin:2vh 0 0 0;
    -moz-box-shadow: 0px 10px 14px -7px #276873;
    -webkit-box-shadow: 0px 10px 14px -7px #276873;
    box-shadow: 0px 10px 14px -7px #276873;
    background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #599bb3), color-stop(1, #408c99));
    background:-moz-linear-gradient(top, #599bb3 5%, #408c99 100%);
    background:-webkit-linear-gradient(top, #599bb3 5%, #408c99 100%);
    background:-o-linear-gradient(top, #599bb3 5%, #408c99 100%);
    background:-ms-linear-gradient(top, #599bb3 5%, #408c99 100%);
    background:linear-gradient(to bottom, #599bb3 5%, #408c99 100%);
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#599bb3', endColorstr='#408c99',GradientType=0);
    background-color:#599bb3;
    -moz-border-radius:8px;
    -webkit-border-radius:8px;
    border-radius:8px;
    display:inline-block;
    cursor:pointer;
    color:#ffffff;
    font-family:Arial;
    font-size:20px;
    font-weight:bold;
    padding:13px 32px;
    text-decoration:none;
    text-shadow:0px 1px 0px #3d768a;
}

.settings-area button:hover{
    margin:2vh 0 0 0;
    -moz-box-shadow: 0px 10px 14px -7px #c37c2c;
    -webkit-box-shadow: 0px 10px 14px -7px #999152;
    box-shadow: 0px 10px 14px -7px #999152;
    background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #b38134), color-stop(1, #999152));
    background:-moz-linear-gradient(top, #b38134 5%, #999152 100%);
    background:-webkit-linear-gradient(top, #b38134 5%, #999152 100%);
    background:-o-linear-gradient(top, #b38134 5%, #999152 100%);
    background:-ms-linear-gradient(top, #b38134 5%, #999152 100%);
    background:linear-gradient(to bottom, #b38134 5%, #999152 100%);
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#599bb3', endColorstr='#408c99',GradientType=0);
    background-color: #999152;
    -moz-border-radius:8px;
    -webkit-border-radius:8px;
    border-radius:8px;
    display:inline-block;
    cursor:pointer;
    color:#ffffff;
    font-family:Arial;
    font-size:20px;
    font-weight:bold;
    padding:13px 32px;
    text-decoration:none;
    text-shadow:0px 1px 0px #3d768a;
}

.settings-area p{
    color:white;
    text-align:left;
    padding:0 0 0 2vw ;
    margin:0;
}

.settings-area p.settingsTitle{
    color:white;
    text-align:center;
    margin:0 0 4vh 0;
}

.loadingPlane{
    font-size:2em;
    color: #51b2ff;
    -ms-transform: rotate(0deg); /* IE 9 */
    -webkit-transform: rotate(0deg); /* Safari prior 9.0 */
    transform: rotate(0deg); /* Standard syntax */
    text-align:left;
    margin: 1vh 0 0 1vw;
    position: relative;
    -webkit-animation-name: loading; /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 10s; /* Safari 4.0 - 8.0 */
    animation: loading 10s infinite;
    animation-timing-function: linear;
}

@-webkit-keyframes loading {
    0%   { left:0; top:0;}
    /*25%  {left:25vw; top:0;}*/
    /*50%  {left:50vw; top:0;}*/
    /*75%  {left:70vw; top:0;}*/
    100% {left:80%; top:0;}
}

/* Standard syntax */
@keyframes loading {
    0%   { left:0; top:0;}
    /*1%   { left:1vw; top:69vh;}*/
    /*2%   { left:1vw; top:68vh;}*/
    /*5%   { left:5vw; top:65vh;}*/
    /*10%   { left:10vw; top:60vh;}*/
    /*15%   { left:15vw; top:55vh;}*/
    /*20%   { left:20vw; top:50vh;}*/
    /*25%  {left:25vw; top:45vh;}*/
    /*30%  {left:30vw; top:40vh;}*/
    /*35%  {left:35vw; top:35vh;}*/
    /*40%  {left:40vw; top:30vh;}*/
    /*45%  {left:45vw; top:25vh;}*/
    /*50%  {left:50vw; top:20vh;}*/
    /*75%  { left:75vw; top:-5vh;}*/
    100% { left:80%; top:0;}
}

.loadingPlaneOutter{

    text-align:left;
}
.newProductUserInput{
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;

}

.newProductUserInput td, .newProductUserInput th {
    border: 1px solid #ddd;
    padding: 8px;
}

.newProductUserInput tr:nth-child(even){background-color: black;}

/*.newProductUserInput tr:hover {background-color: #ddd;}*/

.newProductUserInput th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #4CAF50;
    color: white;

}

.newProductUserInput th,.newProductUserInput td {
    border-radius:10px;
}

.trash:hover{
    color:red;
}
.App {
  text-align: center;
  z-index:1;
  background-image: url("./resources/airplane1.jpg");
  background-size: 300px 100px;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.webSiteContent{
  z-index:2;
  position:absolute;
  height:84vh;
  top:10vh;
  width:90%;
  left:5%;
  /*background: white;*/
  /*color:black;*/
  /*background: black;*/
  color:white;
  /*opacity:0.8;*/
  border-radius:5vh;
  background-image:url(resources/gray.png);
  background-repeat: repeat;
}

.websiteContentInner{

}

.loginIcon{
  position:absolute;
  margin:0 0 0 0;
  width:5vw;

}